<template>
  <div>
    <div v-html="payGateWay"></div>
  </div>
</template>

<script>
export default {
    name: 'orderAlipay',
    data(){
        return {
            payGateWay: ''
        }
    },
    created () {
        this.payGateWay = this.$route.query.htmlData
        this.$nextTick(()=> {
            document.forms[0].submit()
        })
    }
}
</script>

<style lang="less" scoped>
</style>
